import React from 'react';
import MoreIcon from '../MoreIcon';
import './index.scss';

const Benefits = () => {
    return (
        <section className="benefits" id='benefits'>
            <div className="container">
                <div className="row benefits__head">
                    <h2 className="section__title col-12">De voordelen</h2>
                    <div className="col-lg-3"></div>
                    {/* <h6 className="section__subtitle col-lg-6">Digital twins integrate IoT, artificial intelligence, machine learning and software analytics with spatial network graphs.</h6> */}
                    <div className="col-lg-3"></div>
                </div>
                <div className="row">
                    <div className="col-lg-3 col-md-6 benefit">
                        <img src="/img/svg/illustration-insights.svg" alt="" className="benefit__icon" />
                        <h5 className="benefit__title">Notificatiesysteem</h5>
                        <p className="benefit__description">
                            Geeft snel een indicatie van metingen die buiten de norm vallen.
                        </p>
                    </div>
                    <div className="col-lg-3 col-md-6 benefit">
                        <img src="/img/svg/illustration-trends.svg" alt="" className="benefit__icon" />
                        <h5 className="benefit__title">Realtime data</h5>
                        <p className="benefit__description">
                            Realtime en duidelijk inzicht in de data. Daarnaast beschikbaarheid van rapportages en voorspellingen.
                        </p>
                    </div>
                    <div className="col-lg-3 col-md-6 benefit">
                        <img src="/img/svg/illustration-ai.svg" alt="" className="benefit__icon" />
                        <h5 className="benefit__title">Informatie</h5>
                        <p className="benefit__description">
                            Goed inzicht in energieverbruik, luchtkwaliteit en aanwezigheid van personen.
                        </p>
                    </div>
                    <div className="col-lg-3 col-md-6 benefit">
                        <img src="/img/svg/illustration-safety.svg" alt="" className="benefit__icon" />
                        <h5 className="benefit__title">Beschikbaarheid</h5>
                        <p className="benefit__description">
                            De digital Twin is overal beschikbaar, op mobile en desktop.
                        </p>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Benefits;