import React from 'react';
import Button from '../Button';
import './index.scss';

const VideoModal = (props: { toggleVideoModal: () => void }) => {
    return (
        <div className="video-modal">
            <iframe width="730" height="415" src="https://www.youtube.com/embed/2ay_xOuj04U?autoplay=1" frameBorder={0} allowFullScreen={true} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"></iframe>
            <Button className="video-modal__toggle" label="Video sluiten" secondary onClick={props.toggleVideoModal} />
        </div>
    )
};

export default VideoModal;