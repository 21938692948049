import React from 'react';
import Button from '../Button';
import MoreIcon from '../MoreIcon';
import './index.scss';

const Partners = () => {
    return (
        <section id='partners' className="partners section--diamond">
            <div className="container">
                <div className="row">
                    <h2 className="section__title col-12">Partners</h2>
                    <div className="col-3"></div>
                    <h6 className="section__subtitle col-lg-6">Twindle is ontwikkeld door Handpicked agencies en TechTenna.</h6>
                    <div className="col-3"></div>
                    <div className="col-3"></div>
                    <p className="col-lg-6">Handpicked agencies is een familie van digitale agencies en TechTenna is een LoRaWan network operator en leverancier van IoT services. Beiden gevestigd in Breda.</p>
                    <div className="col-3"></div>
                </div>
                <div className="row">
                    <div className="partners__logos">
                        <a href="https://www.handpickedagencies.com/"><img className="partners__logo partners__logo--handpicked" src="/img/svg/logo-handpicked.svg" alt="Logo Handpicked Agencies" title="Handpicked Agencies" /></a>
                        <a href="https://techtenna.com/"><img className="partners__logo partners__logo--techtenna" src="/img/images/logo-techtenna.png" alt="Logo Techtenna" title="Techtenna" /></a>
                    </div>
                </div>
            </div>
            <MoreIcon color="black" />
        </section>
    )
}

export default Partners;