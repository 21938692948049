import React from 'react';
import './index.scss';

const MoreIcon = (props: { color: "black" | "white" }) => {
    return (
        <svg className="more-icon" x="0px" y="0px" width="23px" height="8px" viewBox="0 0 23 8">
            <path id="MoreIcon" className={`more-icon__path more-icon__path--${props.color}`} d="M1,1l10.7,6L22,1" />
        </svg>
    );
};

export default MoreIcon;
