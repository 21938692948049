import React from 'react';
import './index.scss'

const Button = (props: { label: string, className?: string, primary?: boolean, secondary?: boolean, arrow?: boolean, href?: string, onClick?: () => void }) => {
    const generateClasses = () => {
        let classes: string[] = [];

        if (props.primary === true) {
            classes.push('button--primary')
        }
        if (props.secondary === true) {
            classes.push('button--secondary')
        }

        return classes.join(" ");
    }

    const followButtonLink = () => {
        if (props.href) { window.location.replace(props.href); }
        if (props.onClick) { props.onClick() }
    }

    return (
        <button className={`button ${generateClasses()} ${props.className}`} onClick={followButtonLink}>
            <span className='button__label'>{props.label}</span>

            {props.arrow === true ?
                <span className='button__arrow'>
                    <i className="material-icons">chevron_right</i>
                </span> : null}
        </button>
    )
}

export default Button;