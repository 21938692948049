import React, { useState } from 'react';
import MoreIcon from '../MoreIcon';
import './index.scss';

const Header = (props: { toggleVideoModal: () => void }) => {
    return (
        <section className="header">
            <div className="container">
                <div className="row">
                    <div className="col-lg-3"></div>
                    <div className="col-lg-6">
                        <h1 className="header__title">
                            Sla een brug tussen de
                            digitale en fysieke wereld
                            met digital twin-technologie.
                        </h1>
                        <a className="header__subtitle" onClick={props.toggleVideoModal}>
                            <img className="header__video-icon" src="/img/svg/icon-full-video.svg" alt="View video" />
                            <h6>Bekijk volledige video</h6>
                        </a>
                    </div>
                    <div className="col-lg-3"></div>
                </div>
            </div>
            <MoreIcon color={'white'} />
            <div className="header__background"></div>
        </section>
    )
}

export default Header;