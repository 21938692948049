import React, { useEffect, useState } from 'react';
import Scrollspy from 'react-scrollspy';
import { Link, animateScroll } from 'react-scroll';
import Button from '../Button';
import './index.scss';

const Navbar = () => {
    const [navbarActive, setNavbarActive] = useState(false);
    const [mobileNavActive, setMobileNavActive] = useState(false);
    const logoBlack = <img onClick={() => animateScroll.scrollToTop()} src="/img/svg/logo.svg" className="navbar__logo" />;
    const logoWhite = <img onClick={() => animateScroll.scrollToTop()} src="/img/svg/logo-white.svg" className="navbar__logo" />;
    const navbarHeight = 60;

    useEffect(() => {
        // Listen to scroll change
        window.onscroll = () => {
            if (window.scrollY > 260) {
                setNavbarActive(true);
            }
            else if (window.scrollY < 260) {
                setNavbarActive(false);
            };
        };

        // Reset navigation on window size change
        window.onresize = () => {
            if (window.outerWidth > 992) {
                setMobileNavActive(false)
            }
        }
    }, []);

    const toggleMobileNavigation = () => {
        setMobileNavActive(!mobileNavActive);
    };

    const mobileScrollTo = () => {
        setMobileNavActive(false);
    }

    // Combination boolean of nav & mobile nav
    const navActive = navbarActive === true || mobileNavActive === true;

    return (
        <div className={`navbar ${navActive === true ? "navbar--active" : ""}`}>
            <div className="container">
                {navActive === true ? logoBlack : logoWhite}
                <div className="navbar__container">
                    <Link className="navbar__link" activeClass="navbar__link--active" spy smooth duration={1000} offset={-navbarHeight} to="about">Wat is twindle?</Link>
                    <Link className="navbar__link" activeClass="navbar__link--active" spy smooth duration={1000} offset={-navbarHeight} to="benefits">De voordelen</Link>
                    <Link className="navbar__link" activeClass="navbar__link--active" spy smooth duration={1000} offset={-navbarHeight} to="testimonials">Succesverhalen</Link>
                    <Link className="navbar__link" activeClass="navbar__link--active" spy smooth duration={1000} offset={-navbarHeight} to="partners">Partners</Link>
                    <Link className="navbar__link" activeClass="navbar__link--active" spy smooth duration={1000} offset={-navbarHeight} to="contact">Contact</Link>
                </div>
                <Button href="https://demo.twindle.io/" label="Gratis demo" primary arrow className="navbar__button" />
                <button className="navbar__toggle" onClick={toggleMobileNavigation}>
                    <i className="material-icons">
                        {mobileNavActive === true ? 'close' : 'menu'}
                    </i>
                </button>
                <div className={`nav-mobile ${mobileNavActive === true ? "nav-mobile--active" : ""}`}>
                    <div className="nav-mobile__container">
                        <Link className="nav-mobile__link" activeClass="nav-mobile__link--active" spy onClick={mobileScrollTo} smooth duration={1000} offset={-navbarHeight + 5} to="about">Wat is twindle?</Link>
                        <Link className="nav-mobile__link" activeClass="nav-mobile__link--active" spy onClick={mobileScrollTo} smooth duration={1000} offset={-navbarHeight + 5} to="benefits">De voordelen</Link>
                        <Link className="nav-mobile__link" activeClass="nav-mobile__link--active" spy onClick={mobileScrollTo} smooth duration={1000} offset={-navbarHeight + 5} to="testimonials">Succesverhalen</Link>
                        <Link className="nav-mobile__link" activeClass="nav-mobile__link--active" spy onClick={mobileScrollTo} smooth duration={1000} offset={-navbarHeight + 5} to="partners">Partners</Link>
                        <Link className="nav-mobile__link" activeClass="nav-mobile__link--active" spy onClick={mobileScrollTo} smooth duration={1000} offset={-navbarHeight + 5} to="contact">Contact</Link>
                        <Button href="https://demo.twindle.io/" label="Gratis demo" primary arrow className="nav-mobile__button" />
                    </div>
                </div>
                <div className={`nav-mobile__background ${mobileNavActive === true ? "nav-mobile__background--active" : ""}`}></div>
            </div>
        </div >
    )
}

export default Navbar;