import React from 'react';
import Button from '../Button';
import './index.scss';
import SocialButtons from '../SocialButtons';

const Footer = () => {
    return (
        <section id="footer" className="footer">
            <div className="container">
                <div className="row footer__top">
                    <img src="/img/svg/logo-white.svg" alt="Twindle Logo" className="footer__logo col-12" />
                    <div className="col-lg-3"></div>
                    <div className="col-lg-6">
                        <p className="footer__description">© Copyright {new Date().getFullYear().toString()}. Alle rechten voorbehouden. <br />  Twindle is een startup van Handpicked Agencies & Techtenna.</p>
                        <ul className="footer__links">
                            <li className="footer__link"><a href="#/privacypolicy" title="Privacy Policy">Privacy Policy</a></li>
                            <li className="footer__link"><a href="#/privacypolicy" title="Algemene voorwaarden">Algemene voorwaarden</a></li>
                            <li className="footer__link"><a href="#/privacypolicy" title="Cookies">Cookies</a></li>
                            <li className="footer__link"><a href="#/privacypolicy" title="Partner worden">Partner worden</a></li>
                        </ul>
                    </div>
                    <div className="col-lg-3"></div>
                </div>
                <div className="row footer__columns">
                    <div className="col-lg-4 footer-column">
                        <h5 className="footer-column__title">Meer weten?</h5>
                        <p className="footer-column__text">Wil je meer weten over de technologie van Twindle, de mogelijkheden of heb je andere vragen? Neem vooral contact met ons op.</p>
                        <Button label="Naar contactformulier" primary arrow />
                    </div>
                    <div className="col-lg-4 footer-column">
                        <h5 className="footer-column__title">Volg ons</h5>
                        <p className="footer-column__text">Twindle is vol in ontwikkeling en we zijn elke dag bezig om onze technologie te verbeteren. Volg onze reis en blijf up-to-date.</p>
                        <div className="footer__social-icons">
                            <SocialButtons />
                        </div>
                    </div>
                    <div className="col-lg-4 footer-column">
                        <h5 className="footer-column__title">Mede mogelijk gemaakt door</h5>
                        <p className="footer-column__text">Twindle is mede tot stand gekomen door subsidie van Gemeente Breda.</p>
                        <a href="https://www.breda.nl/" title="Gemeente Breda"><img className="footer__partner-logo" src="/img/svg/logo-breda-wit.svg" alt="Logo Gemeente Breda" /></a>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Footer;