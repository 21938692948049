import React, { useState } from 'react';
import Navbar from './components/Navbar';
import Benefits from './components/Benefits'
import Footer from './components/Footer';
import Header from './components/Header';
import About from './components/About';
import Partners from './components/Partners';
import Testimonial from './components/Testimonial';
import VideoModal from './components/VideoModal';

const App = () => {
    const [videoToggle, setVideoToggle] = useState(false);
    const toggleVideo = () => {
        setVideoToggle(!videoToggle);
    }

    return (
        <>
            {videoToggle === true ? <VideoModal toggleVideoModal={toggleVideo} /> : null}
            <Navbar />
            <Header toggleVideoModal={toggleVideo} />
            <About />
            <Benefits />
            {/* <Testimonial /> */}
            <Partners />
            <Footer />
        </>
    )
};

export default App;