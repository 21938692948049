import React from 'react';
import Button from '../Button';
import MoreIcon from '../MoreIcon';
import './index.scss';

const About = () => {
    return (
        <section id='about' className="about section--diamond">
            <div className="container">
                <div className="row">
                    <h2 className="section__title col-12">Wat is Twindle?</h2>
                    <div className="col-3"></div>
                    <h6 className="section__subtitle col-lg-6">De digitale Twindle dubbelganger helpt bedrijven te optimaliseren, innoveren en nieuwe services te bieden.</h6>
                    <div className="col-3"></div>
                    <div className="col-3"></div>
                    <p className="col-lg-6">IoT devices worden opgehangen in ruimtes, en de data daarvan wordt realtime beschikbaar gemaakt in een 3D model dat in de browser beschikbaar is.</p>
                    <div className="col-3"></div>
                </div>
                <Button label="Interesse in een gesprek?" secondary className="button--cta"/>
            </div>
            <MoreIcon color="black" />
        </section>
    )
}

export default About;